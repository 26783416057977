import React from 'react'
import './index.css'

const Spinner = () => {
	return (
		<div className='container'>
			<div class="loader">Loading...</div>
		</div>

	)
}

export default Spinner